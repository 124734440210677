import React from 'react'
import { Link } from 'gatsby'

// Images 
import blogImg from '../../assets/images/blog/b1.jpg'
import blogImg2 from '../../assets/images/blog/b2.jpg'
import blogImg3 from '../../assets/images/blog/b3.jpg'
import blogImg4 from '../../assets/images/blog/b4.jpg'
import blogImg5 from '../../assets/images/blog/b5.jpg'
import blogImg6 from '../../assets/images/blog/b6.jpg'

const BlogPostTwo = () => {
    return (
        <section className="blog pt-70 pb-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="blog-card blog-card-2">
                            <div className="blog-img-area">
                                <Link to="/blog-details">
                                    <img 
                                        src={blogImg} 
                                        alt="blog"
                                    />
                                </Link>
                                <div className="blog-img-date">
                                    <span>19</span>
                                    <span>Aug</span>
                                </div>
                            </div>
                            <div className="blog-text-area">
                                <div className="blog-date">
                                    <ul>
                                        <li><i className="far fa-user-circle"></i> By <Link to="/author">Admin</Link> </li>
                                        <li><i className="far fa-comments"></i> 2 Comments</li>
                                    </ul>
                                </div>
                                <h4><Link to="/blog-details">Digital Marketing: Staying Relevant To Empowered Consumer</Link></h4>
                                <Link  className="default-button default-button-2" to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="blog-card blog-card-2">
                            <div className="blog-img-area">
                                <Link to="/blog-details">
                                    <img 
                                        src={blogImg3} 
                                        alt="blog"
                                    />
                                </Link>
                                <div className="blog-img-date">
                                    <span>18</span>
                                    <span>Aug</span>
                                </div>
                            </div>
                            <div className="blog-text-area">
                                <div className="blog-date">
                                    <ul>
                                        <li><i className="far fa-user-circle"></i> By <Link to="/author">Admin</Link> </li>
                                        <li><i className="far fa-comments"></i> 2 Comments</li>
                                    </ul>
                                </div>
                                <h4><Link to="/blog-details">Grow Your Business With A Digital Marketing Strategy</Link></h4>
                                <Link  className="default-button default-button-2" to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="blog-card blog-card-2">
                            <div className="blog-img-area">
                                <Link to="/blog-details">
                                    <img 
                                        src={blogImg2} 
                                        alt="blog"
                                    />
                                </Link>
                                <div className="blog-img-date">
                                    <span>17</span>
                                    <span>Aug</span>
                                </div>
                            </div>
                            <div className="blog-text-area">
                                <div className="blog-date">
                                    <ul>
                                        <li><i className="far fa-user-circle"></i> By <Link to="/author">Admin</Link> </li>
                                        <li><i className="far fa-comments"></i> 2 Comments</li>
                                    </ul>
                                </div>
                                <h4><Link to="/blog-details">5 Inexpensive Online Tools To Get Your Business Off The Ground</Link></h4>
                                <Link  className="default-button default-button-2" to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="blog-card blog-card-2">
                            <div className="blog-img-area">
                                <Link to="/blog-details">
                                    <img 
                                        src={blogImg4} 
                                        alt="blog"
                                    />
                                </Link>
                                <div className="blog-img-date">
                                    <span>20</span>
                                    <span>Aug</span>
                                </div>
                            </div>
                            <div className="blog-text-area">
                                <div className="blog-date">
                                    <ul>
                                        <li><i className="far fa-user-circle"></i> By <Link to="/author">Admin</Link> </li>
                                        <li><i className="far fa-comments"></i> 2 Comments</li>
                                    </ul>
                                </div>
                                <h4><Link to="/blog-details">L&C Mortgages Appoints Crowd as Its Digital Media Agency</Link></h4>
                                <Link  className="default-button default-button-2" to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="blog-card blog-card-2">
                            <div className="blog-img-area">
                                <Link to="/blog-details">
                                    <img 
                                        src={blogImg5} 
                                        alt="blog"
                                    />
                                </Link>
                                <div className="blog-img-date">
                                    <span>15</span>
                                    <span>Aug</span>
                                </div>
                            </div>
                            <div className="blog-text-area">
                                <div className="blog-date">
                                    <ul>
                                        <li><i className="far fa-user-circle"></i> By <Link to="/author">Admin</Link> </li>
                                        <li><i className="far fa-comments"></i> 2 Comments</li>
                                    </ul>
                                </div>
                                <h4><Link to="/blog-details">Brave Bison Partners with the BBC’s The Game UK Once Again</Link></h4>
                                <Link  className="default-button default-button-2" to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="blog-card blog-card-2">
                            <div className="blog-img-area">
                                <Link to="/blog-details">
                                    <img 
                                        src={blogImg6} 
                                        alt="blog"
                                    />
                                </Link>
                                <div className="blog-img-date">
                                    <span>13</span>
                                    <span>Aug</span>
                                </div>
                            </div>
                            <div className="blog-text-area">
                                <div className="blog-date">
                                    <ul>
                                        <li><i className="far fa-user-circle"></i> By <Link to="/author">Admin</Link> </li>
                                        <li><i className="far fa-comments"></i> 2 Comments</li>
                                    </ul>
                                </div>
                                <h4><Link to="/blog-details">Leading Marketing Agency Digital Announces Exciting Acquisition</Link></h4>
                                <Link  className="default-button default-button-2" to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="paginations mt-30">
                    <ul>
                        <li className="active"><Link  to="/blog">1</Link></li>
                        <li><Link  to="/blog">2</Link></li>
                        <li><Link  to="/blog">3</Link></li>
                        <li><Link  to="/blog"><i className="fas fa-angle-right"></i></Link></li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default BlogPostTwo
